window.__tnt || (window.__tnt = {});

(function($, __tnt) {

    var me = __tnt.regions || (__tnt.regions = {}),
        stickyAnchor = {};

    me.stickyAnchor = {
        hasContent: false,
        init: function() {
            stickyAnchor.closeBtnRendered = false;
            stickyAnchor.div = $('#sticky-anchor');
            stickyAnchor.region = $('#tncms-region-page_sticky_anchor');
            stickyAnchor.icon = 'fas tnt-minus';

            me.stickyAnchor.checkContent();
            if (me.stickyAnchor.hasContent === true) {
                me.stickyAnchor.renderClose();
            }

            me.stickyAnchor.observer.observe(stickyAnchor.region[0], {childList:true, subtree:true});
        },
        checkContent: function() {
            var region = stickyAnchor.region[0];

            if (region.hasChildNodes()) {
                var blocks = region.children;

                for (var i=0; i<blocks.length; i++) {
                    if (blocks[i].querySelector('.tnt-ads-container, .tnt-ads')) {
                        var ad_container = blocks[i].querySelector('.tnt-ads-container, .tnt-ads');

                        if (
                            ad_container.offsetHeight > 1
                            && ad_container.firstElementChild
                            && ad_container.firstElementChild.offsetParent !== null
                            && ((
                                ad_container.firstElementChild.firstElementChild
                                && ad_container.firstElementChild.firstElementChild.offsetParent !== null
                            ) || (
                                ad_container.firstElementChild.tagName == 'IMG'
                            ))
                        ) {
                            me.stickyAnchor.hasContent = true;
                            me.stickyAnchor.observer.disconnect();
                        }
                    } else {
                        if (blocks[i].firstElementChild && blocks[i].firstElementChild.offsetParent !== null) {
                            me.stickyAnchor.hasContent = true;
                            me.stickyAnchor.observer.disconnect();
                        }
                    }
                }
            }
        },
        renderClose: function() {
            if (stickyAnchor.closeBtnRendered === false) {
                stickyAnchor.div.prepend('<div class="sticky-anchor-close" data-toggle="collapse" data-target="#tncms-region-page_sticky_anchor"><i class="' + stickyAnchor.icon + '"></i></div>');

                stickyAnchor.region.on('hide.bs.collapse', function(){
                    $(this).siblings('.sticky-anchor-close').find('[data-fa-i2svg]').attr('data-icon', 'plus');
                });
                stickyAnchor.region.on('show.bs.collapse', function(){
                    $(this).siblings('.sticky-anchor-close').find('[data-fa-i2svg]').attr('data-icon', 'minus');
                });

                stickyAnchor.closeBtnRendered = true;
            }
        },
        observer: new MutationObserver(function(mutations, observer){
            for (var i=0; i<mutations.length; i++) {
                if (mutations[i].type == 'childList') {
                    me.stickyAnchor.checkContent();
                    if (me.stickyAnchor.hasContent === true) {
                        me.stickyAnchor.renderClose();
                    }
                }
            }
        })
    };

    var stickySide = {};
    me.stickySide = {
        init: function(region, spacer, type, container) {
            var regionBlocks = region.getElementsByClassName('tncms-block');
            if (regionBlocks.length > 0) {
                var populatedBlocks = false;
                for (var i = 0; i < regionBlocks.length; i++) {
                    if (regionBlocks[i].hasChildNodes()) {
                        populatedBlocks = true;
                        break;
                    }
                }
                if (populatedBlocks){
                    me.stickySide.getOffset();
                    me.stickySide.determineStick(region, spacer, type, container);

                    window.addEventListener('resize', function() {
                        me.stickySide.determineStick(region, spacer, type, container);
                    });
                }
            }

            if (typeof window.ResizeObserver !== 'undefined') {
                me.stickySide.resizeObserver = new ResizeObserver(function(entries) {
                    for (var i = 0; i < entries.length; i++) {
                        var recalcEvent = new Event('sticky_kit:recalc');
                        document.body.dispatchEvent(recalcEvent);
                    }
                });
            }
        },
        getClosest: function (elem, selector) {
            for ( ; elem && elem !== document; elem = elem.parentNode ) {
                if ( elem.matches( selector ) ) return elem;
            }
            return null;
        },
        getOffset: function() {
            stickySide.offset = 30;
            if (document.body.classList.contains('fixed-nav') || document.body.classList.contains('fixed-scroll-nav')) {
                stickySide.offset += document.getElementById('main-navigation').offsetHeight;
            }
        },
        determineStick: function(region, spacer, type, container) {
            me.stickySide.getOffset();
            if ((type == 'siderail' && window.innerWidth >= 992) || (type == 'aside' && (window.innerWidth >= 1200 || (window.innerWidth >= 768 && window.innerWidth < 992)))) {
                var closestCont = this.getClosest(region, container),
                parentSelection = (spacer.parentElement.offsetWidth == closestCont.offsetWidth) ? this.getClosest(closestCont.parentNode, container) : closestCont;

                window.stick_in_parent(region, {
                    'parent': parentSelection,
                    'offset_top': stickySide.offset,
                    'recalc_every': 50,
                    'spacer': spacer
                });

                if (me.stickySide.resizeObserver) me.stickySide.resizeObserver.observe(region);
            } else {
                var detachEvent;
                if (window.CustomEvent) {
                    detachEvent = new CustomEvent('sticky_kit:detach');
                } else {
                    detachEvent = document.createEvent('CustomEvent');
                    event.initCustomEvent('sticky_kit:detach', true, true);
                }
                region.dispatchEvent(detachEvent);
                if (me.stickySide.resizeObserver) me.stickySide.resizeObserver.unobserve(region);
            }
        }
    };

    var stickySideBreakout = {};
    me.stickySideBreakout = {
        init: function() {
            if (window.innerWidth >= 992) {
                var heights = {};
                var observer = new MutationObserver(function(mutations, observer) {
                    for (var i = 0; i < mutations.length; i++) {
                        if (mutations[i].target.parentElement.classList.contains('tnt-ads')) {
                            var adDiv = mutations[i].target.closest('.tnt-ads.dfp-ad');
                            var stickyDiv = mutations[i].target.closest('.fixed-sticky-rail');
                            // ad height is different than previous ad height
                            if (adDiv.offsetHeight != heights['fixed-sticky-rail-'+stickyDiv.dataset.pos]) {
                                heights['fixed-sticky-rail-'+stickyDiv.dataset.pos] = adDiv.offsetHeight;
                                var recalcEvent = new Event('sticky_kit:recalc');
                                document.body.dispatchEvent(recalcEvent);
                            }
                        }
                    }
                });

                document.querySelectorAll('.fixed-sticky-rail').forEach(function(region) {
                    var textRow = region.dataset.pos,
                        spacer = region.parentElement,
                        parent = document.querySelector('.text-row[data-breakout="' + textRow + '"]');

                    heights['fixed-sticky-rail-'+region.dataset.pos] = region.offsetHeight;

                    window.stick_in_parent(region, {
                        'parent': parent,
                        'offset_top': 75,
                        'recalc_every': 50,
                        'spacer': spacer
                    });

                    observer.observe(region, {childList:true, subtree:true});
                });
            }
        }
    };

})(jQuery, __tnt);